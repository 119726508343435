import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardListCheck } from "@shfortawesome/pro-duotone-svg-icons";
import classes from "./UnseenReports.module.scss";
import { useUnseenReportsWidget } from "../../../api/hooks/widgets/useUnseenReportsWidget";
import { useParams } from "react-router";

export const UnseenReports = () => {
	const { patientId } = useParams();
	const unseenReportsQuery = useUnseenReportsWidget(patientId);

	return (
		<div className={classes.unseenReports} data-testid="unreadReports">
			<div className={classes.header}>
				<p className={classes.title}>Unseen reports</p>
				<FontAwesomeIcon icon={faClipboardListCheck} className={classes.icon} />
			</div>
			<p className={classes.number}>{unseenReportsQuery?.data?.unseen ?? "-"}</p>
		</div>
	);
};
