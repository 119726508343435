import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNote } from "@shfortawesome/pro-duotone-svg-icons";
import classes from "./PatientNotes.module.scss";

export const PatientNotes = () => {
	return (
		<div className={classes.PatientNotes} >
			<div className={classes.PatientNotesTitles}>
			<FontAwesomeIcon icon={faNote} className={classes.Icon} />
				<p className={classes.Title}>Patient notes</p>
				</div>
				<p className={classes.Subtitle}>Coming soon</p>
		</div>
	);
};
