import classes from "./AbnormalResults.module.scss";
import { ActionPaths } from "../../../layouts/Layout/actionPaths";
import { Link } from "../../Link/Link";
import { ExpandedRequestRow } from "../../RequestCard/RequestCard";
import { NoAccessMessage } from "../../NoAccessMessage/NoAccessMessage";
import { useAbnormalResults } from "../../../api/hooks/useAbnormalResults";
import { useEffect, useRef } from "react";

export const AbnormalResults = ({ patientId, hasPermission }) => {
	const { data, isFetching, fetchNextPage: fetchMoreResults } = useAbnormalResults(patientId, 5);
	const endOfListRef = useRef(null);

	useEffect(() => {
		const observer = new IntersectionObserver(elements => {
			elements.forEach(element => {
				if (element.isIntersecting) {
					fetchMoreResults();
				}
			});
		});

		if (endOfListRef?.current !== null) {
			observer.observe(endOfListRef.current);
		}

		return () => {
			observer.disconnect()
		}
	}, [endOfListRef, fetchMoreResults]);

	if (!hasPermission) {
		return <NoAccessMessage />
	}

	return (
		<div className={classes.AbnormalResults}>
			<div className={classes.Header}>
				<p className={classes.Title}>Latest abnormal results</p>
				<Link text="View orders" icon to={`../orders/${ActionPaths.ReviewResults(patientId)}`} />
			</div>

			<div className={classes.Table}>
				<div className={classes.TableHeader}>Service Request</div>
				<div className={classes.TableBody}>
					{data?.map((request, i) => (
						<ExpandedRequestRow
							key={i}
							request={request}
							isResultsCard
							to={`../orders/${ActionPaths.ReviewResults(patientId)}/${ActionPaths.FormHistoryResults(
								request.formComponentId,
							)}`}
						/>
					))}
					<div ref={endOfListRef} />
					{isFetching && <ExpandedRequestRow isResultsCard ghost />}
				</div>
			</div>
		</div>
	);
};
